import React from 'react'
import Button from 'components/Button'
import useTranslation from 'translations/hooks/useTranslation'

export default function Error() {
  const { t } = useTranslation()

  return (
    <>
      <div className="heading-wrapper heading-wrapper--orange">
        <div className="container container--small">
          <h1 className="heading-ultra">404</h1>
          <p className="paragraph paragraph--white">
            Hups 😳 {t('common.notFoundTitle')}
          </p>
        </div>
      </div>

      <div className="container center margin-top-big margin-bottom-big">
        <Button text={t('common.backToIntro')} href="/" />
      </div>
    </>
  )
}
